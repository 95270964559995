/* eslint-disable no-console */
import * as Sentry from '@sentry/node';
import ApiError from './errors/apiError';

// FormData is not defined in the backend
// /!\ IMPORTANT: FormData in the backend doesn't support
// empty, null, or undefined values
const FormData =
  typeof window !== 'undefined' ? window.FormData : require('form-data');

// https://github.com/developit/unfetch#caveats
export const checkStatus = (response) => {
  if (response.ok) {
    return response;
  }

  let message = '';
  if (response.status === 429) {
    message =
      "We're sorry, but you have sent too many requests.  Please try again later.";
  } else {
    message = `${response.status} status from ${response.url}`;
  }

  const error = new ApiError(message, response);
  throw error;
};

export const handleError = (message: string, error: ApiError) => {
  const apiErrorMessage = error.message;

  if (process.env.NODE_ENV === 'production') {
    Sentry.withScope((scope) => {
      scope.setExtra('error-message', message);
      scope.setExtra('api-error-message', apiErrorMessage);

      Sentry.captureException(error);
    });
  } else {
    console.error('error-message', message);
    console.error('api-error-message', apiErrorMessage);
  }
};

export const objectToFormData = (
  obj: object,
  form?: FormData,
  namespace?: string
) => {
  const fd = form || new FormData();
  let formKey;

  Object.keys(obj).map((property) => {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      if (namespace) {
        formKey = `${namespace}[${property}]`;
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File, use recursion.
      // or we're in the backend in which case `File` will be undefined
      if (
        typeof obj[property] === 'object' &&
        !(typeof File !== 'undefined' && obj[property] instanceof File)
      ) {
        objectToFormData(obj[property], fd, property);
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
    }
  });

  return fd;
};

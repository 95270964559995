class ApiError extends Error {
  response: Response;

  constructor(message, response) {
    super(message);
    this.name = 'ApiError';
    this.message = message;
    this.response = response;
  }
}

export default ApiError;

import fetch from 'isomorphic-unfetch';
import { RequestOptions } from '../../interfaces/api';

const apiFetch = (token: string, url: string, options: RequestOptions) => {
  if (!token) {
    throw new Error('token is required to make an api request');
  }

  const { headers, ...rest } = options;

  return fetch(`${process.env.API_URL}/${url}`, {
    ...rest,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      ...headers,
    },
  });
};

export default apiFetch;
